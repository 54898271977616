<template>
  <v-row no-gutters>
    <v-col>      

    <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

    <v-alert v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer" text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>

    <div v-else>

        <v-expand-transition>
          <v-card
            v-if="status"
            :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
            rounded="xl"
            class="mx-auto mt-md-9"
            :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
            :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 600"
          >
            <v-img
              class="white--text align-end"
              :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
              src="@/assets/images/pages/header-01.jpg"
              :height="$vuetify.breakpoint.smAndDown ? 260 : 260"
              gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
            >
              <div class="card-img--text card-img--text-type-1">
                <div class="text-h4 font-weight-medium mb-0">Yaşlandırma Raporu</div>
                <p class="text-body-2 white--text font-weight-thin mb-1" v-text="msg" />
                <v-progress-linear v-show="loading" color="white" indeterminate></v-progress-linear>
              </div>
            </v-img>
            <v-expand-transition>
              <div v-show="!loading">
                <v-card-text class="pa-0">
                  <v-row :class="!$vuetify.breakpoint.smAndDown ? 'pt-6' : 'pt-6'">
                    <v-col cols="12" md="6" class="pl-md-9 pr-md-2 px-9">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted1"
                            label="Başlangıç Tarihi"
                            persistent-hint
                            :prepend-icon="icons.mdiCalendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="date1"
                          no-title
                          color="primary"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" class="pl-md-2 pr-md-9 px-9">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            label="Bitiş Tarihi"
                            persistent-hint
                            :prepend-icon="icons.mdiCalendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker v-model="date2" no-title color="primary" @input="menu2 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <!-- <v-divider v-if="!$vuetify.breakpoint.smAndDown"></v-divider> -->
                </v-card-text>

                <v-card-actions class="pb-9 pt-9">
                  <v-spacer />
                  <v-btn
                    color="secondary"
                    class="text-capitalize text-body-1"
                    rounded
                    x-large
                    @click="fetchDatas()"
                  >
                    Ara
                  </v-btn>
                </v-card-actions>
              </div>
            </v-expand-transition>
          </v-card>
        </v-expand-transition>


        <v-card
          v-if="!status"
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
            <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons.mdiFinance }}
              </v-icon>
             Yaşlandırma Raporu
            </v-toolbar-title>

            <v-spacer></v-spacer>

          <!--   <download-excel
              v-if="!$vuetify.breakpoint.smAndDown"
              :data="json_data"
              :fields="json_fields"
              name="AGING_REPORT__TUKAS.xls"
            >
              <v-btn plain text small>
                <v-icon left>
                  {{ icons.mdiDownload }}
                </v-icon>
                İndir
              </v-btn>
            </download-excel> -->
    
          </v-toolbar>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="dataListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :loading="loading"
          >
            <template #[`item.TUKCUSTGRP`]="{ item }">
              <v-chip small label color="grey">
                <span class="black--text"> {{ item.TUKCUSTGRP }}</span>
              </v-chip>
            </template>
            <template #[`item.TUKSALDEPT`]="{ item }">
              <v-chip small label color="grey">
                <span class="black--text"> {{ item.TUKSALDEPT }}</span>
              </v-chip>
            </template>
            <template #[`item.TUKSALDEPTTEXT`]="{ item }">
              <v-chip small label color="grey">
                <span class="black--text"> {{ item.TUKSALDEPTTEXT }}</span>
              </v-chip>
            </template>
            <template #[`item.CUSTOMER`]="{ item }">
              <v-chip small label color="grey">
                <span class="black--text"> {{ item.CUSTOMER }}</span>
              </v-chip>
            </template>
            <template #[`item.NAME1`]="{ item }">
              <v-chip small label color="grey">
                <span class="black--text"> {{ item.NAME1 }}</span>
              </v-chip>
            </template>
            
            <template #[`item.TUKCIRO`]="{ item }">
              <span class="success--text text-caption font-weight-bold"> {{ item.TUKCIRO | currency }}</span>
            </template>

         
            <template #[`item.TUKORTTAS`]="{ item }">
              <v-chip small label color="grey">
                <span class="error--text text-caption font-weight-bold"> {{ item.TUKORTTAS | currency}}</span>
              </v-chip>
            </template>
            <template #[`item.AVGDAY`]="{ item }">
              <v-chip small label color="grey">
                <span class="error--text text-caption font-weight-bold"> {{ item.AVGDAY | currency}}</span>
              </v-chip>
            </template>
            <template #[`item.ABAL1S`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.ABAL1S | currency }}</span>
              </v-chip>
            </template>
            <template #[`item.CEKKENDI`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.CEKKENDI | currency }}</span>
              </v-chip>
            </template>
            <template #[`item.CEKCIRO`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.CEKCIRO | currency }}</span>
              </v-chip>
            </template>            

            <template #[`item.AVGDATE`]="{ item }">
              <span class="text--secondary text-caption"> {{ item.AVGDATE.substring(0, 10) }}</span>
            </template> 

            <template #[`item.ABALSUM`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.ABALSUM | currency}}</span>
              </v-chip>
            </template>     
            <template #[`item.ABAL1C`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.ABAL1C | currency}}</span>
              </v-chip>
            </template>    
            <template #[`item.BALANCE`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.BALANCE | currency}}</span>
              </v-chip>
            </template>    
            <template #[`item.CEKLIMIT`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.CEKLIMIT | currency}}</span>
              </v-chip>
            </template> 
            <template #[`item.TUKTEMINAT`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.TUKTEMINAT | currency}}</span>
              </v-chip>
            </template> 
            <template #[`item.TUKRISKWOASSUR`]="{ item }">
              <v-chip small label color="grey">
                <span class="error--text text-caption font-weight-bold"> {{ item.TUKRISKWOASSUR | currency }}</span>
              </v-chip>
            </template> 
            <template #[`item.NAKITBAK`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.NAKITBAK | currency }}</span>
              </v-chip>
            </template> 
            <template #[`item.KREDIBAK`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.KREDIBAK | currency}}</span>
              </v-chip>
            </template> 
            <template #[`item.TAHSILAT`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.TAHSILAT | currency}}</span>
              </v-chip>
            </template> 
            <template #[`item.KAPANACAK`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.KAPANACAK | currency}}</span>
              </v-chip>
            </template> 
             <template #[`item.TUKACIKLIMIT`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.TUKACIKLIMIT| currency }}</span>
              </v-chip>
            </template> 
            <template #[`item.TUKRISK`]="{ item }">
              <v-chip small label color="grey">
                <span class="secondary--text text-caption font-weight-bold"> {{ item.TUKRISK | currency}}</span>
              </v-chip>
            </template> 
            <template #[`item.CURRENCY`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.CURRENCY }}</span>
              </v-chip>
            </template> 
            <template #[`item.KAPANAN`]="{ item }">
              <v-chip small label color="grey">
                <span class="text--secondary"> {{ item.KAPANAN | currency }}</span>
              </v-chip>
            </template> 
            
           
          </v-data-table>
        </v-card>

      </div>
    <!--   <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div> -->
    </v-col>
  </v-row>
</template>

<script>
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import {
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiFinance,
  mdiDelete,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiPlusThick,
  mdiSend,
  mdiSquareEditOutline,
} from '@mdi/js'
import { computed,onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {
  components: {    
    CustomerList,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const status = ref(false)
    const sendData = ref({})
    const date2 = ref(new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10))
    const date1 = ref(new Date(new Date().setDate(new Date().getDate() - 45)).toISOString().substr(0, 10))
    const menu1 = ref(false)
    const menu2 = ref(false)
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
   

    onMounted(() => {
      /* if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        //fetchDatas()
      } */


      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
      }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        if (store.state.plasiyerCustomer) {
          status.value = true
          //loading.value = true
          //fetchDatas()
        }
      },
    )

    const dataListTable = ref([])
    const dataItem = ref({})
    let tableColumns
     if (userData.role == 'PLSYR') {
      tableColumns = [
        {
          text: 'Müşteri Grubu',
          value: 'TUKCUSTGRP',
          width: '90px',
          sortable: false,
        },
         {
          text: 'Srm.Kodu',
          value: 'TUKSALDEPT',
          width: '90px',
          sortable: false,
        },
        {
          text: 'Sorumlu Adı',
          value: 'TUKSALDEPTTEXT',
          width: '120px',
        },
        {
          text: 'Müşteri Kodu',
          value: 'CUSTOMER',
          width: '90px',     
        },
        {
          text: 'Müşteri Unvanı',
          value: 'NAME1',
          align: 'left min-width-400',
        },
        {
          text: 'Ciro',
          value: 'TUKCIRO',
          width: '150px',
          align: 'right',
          sortable: false,
        },

        {
          text: 'Ort.Tahsilat',
          value: 'TUKORTTAS',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Gk.Gün Sy.',
          value: 'AVGDAY',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Senetler',
          value: 'ABAL1S',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Çekler(Kendi)',
          value: 'CEKKENDI',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Çekler(Ciro)',
          value: 'CEKCIRO',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        {
          text: 'Ort.Vade',
          value: 'AVGDATE',
          sortable: false,
          width: '100px',
        }, 
        {
          text: 'Açık Bakiye',
          value: 'ABALSUM',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Çekler',
          value: 'ABAL1C',
          width: '100px',
          align: 'center',
          sortable: false,
        },
     
        {
          text: 'Alacaklar Toplamı',
          value: 'BALANCE',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Çek Limiti',
          value: 'CEKLIMIT',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Teminat',
          value: 'TUKTEMINAT',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Teminat Harici Risk',
          value: 'TUKRISKWOASSUR',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Nakit Bakiye',
          value: 'NAKITBAK',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Kredi Kartı Bakiye',
          value: 'KREDIBAK',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Tahsilat',
          value: 'TAHSILAT',
          width: '100px',
          align: 'center',
          sortable: false,
        }, 
        {
          text: 'Kapanacak',
          value: 'KAPANACAK',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Açık Limit',
          value: 'TUKACIKLIMIT',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Risk',
          value: 'TUKRISK',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: 'P.Br.',
          value: 'CURRENCY',
          width: '60px',
          align: 'center',
          sortable: false,
        }, 
        {
          text: 'Kapanan',
          value: 'KAPANAN',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        
      ]
    } 

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])

    const json_data = ref([])

    const fetchDatas = () => {
      const [day, month, year] = computedDateFormatted.value.split('/')
      const newEndDate = `${year}-${month}-${day}`

      const [day1, month1, year1] = computedDateFormatted1.value.split('/')
      const newStartDate = `${year1}-${month1}-${day1}`
  
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      debugger
      store
      .dispatch('postMethod', {
          method: 'getCustomerFINAging',
          'search[startDate]': newStartDate,
          'search[endDate]': newEndDate,
        })
        .then(response => {
          if (response.error == 0) {
            debugger
            dataListTable.value = response.detail
           // totalDataListTable.value = Number(response.response.pagination.totalRec)
            json_data.value = response.detail.result
          
            status.value = false
            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Hata',
              text: 'Bir Hata Oluştu',
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')
      return `${day}/${month}/${year}`
    }

    let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

    const parseDate = dates => {
      if (!dates) return null
      const [month, day, year] = dates.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }

    const computedDateFormatted = computed(() => formatDate(date2.value))
    const computedDateFormatted1 = computed(() => formatDate(date1.value))

    watch(date2, () => {
      dateFormatted = formatDate(date2.value)
    })

    return {
      sendData,
      json_data,
      dataItem,
      dataListTable,
      tableColumns,
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      selectedRows,
      fetchDatas,
      status,
      userData,

      menu1,
      menu2,
      date2,
      date1,
      dateFormatted,
      computedDateFormatted,
      computedDateFormatted1,
      parseDate,
      formatDate,
      msg,
  
      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFinance,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiDownload,
        mdiSend,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
      json_fields: {
        ID: 'id',
        'Kart No': 'cc_no',

        Fiyat: {
          field: 'amount',
          callback: value => {
            return `${Number(value.slice(0, -1))}`
          },
        },
        'Taksit Sayısı': {
          field: 'installment_count',
          callback: value => {
            return value == 1 ? 'Tek Çekim' : value + 'Taksit'
          },
        },
        'İşlem Tarihi': {
          field: 'datetime',
          callback: value => {
            return `${value.slice(0, 16)}`
          },
        },
        Açıklama: 'ltext',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
